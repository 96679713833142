import React from "react";
import HtmlParser from "react-html-parser";
import IconRenderer from '../../IconRenderer/IconRenderer';
import {GoogleReviewIcon} from "../../icon/icon";
import ElfsightWidget from "../../Elfsight/ElfsightWidget";

const FormLeftContent = (props) => {
  return (
    <div className={`form-left-content`}>
        {props?.title && <h3 className="spacing-bottom-32">{props?.title}</h3> }
        
        {props?.content && <div className="content">{HtmlParser(props?.content)}</div>}
        {props?.Show_Review &&
            <div className="elfsight-reviews-badge">
                <GoogleReviewIcon />
                <ElfsightWidget widgetID={props?.WidgetID} />
            </div>
        }
        {props?.contactinfo?.length >0 &&
        <ul className="contact-list mx-0 mb-0">
          {props?.contactinfo.map((item, index) => (
            <React.Fragment key={item.id}>
              <li className="spacing-bottom-24">
                <div className="list-icon"><IconRenderer iconType={item?.Icon_Type} /></div>
                <div className="list-info">
                  <span className="list-label text-18">{item.Label}</span>
                  <a className="list-link text-20 bold" href={
                        item.Type === 'Phone'
                        ? `tel:${item.Link}`
                        : item.Type === 'Email'
                        ? `mailto:${item.Link}`
                        : item.Type === 'ExternalLink'
                        ? item.Link
                        : null
                    } target={item.Type === 'ExternalLink' ? '_blank' : '_self'} rel={item.Type === 'ExternalLink' ? 'noopener noreferrer' : null}>
                    {item.Link}
                  </a>
                </div>
              </li>
            </React.Fragment>
          ))}
        </ul>
        }
    </div>
  );
};

export default FormLeftContent;