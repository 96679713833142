import React from 'react'
import { Form } from "react-bootstrap"


const SelectField = ({name, required, placeholder, labelClass, values, handlechange,  fieldClass, label, grpmd, componentprops, checked}) => {
  console.log("checked", checked)
  var selectvalues = []
  {values.map(val =>selectvalues.push({ value:val.value, label: val.label } ))}
  return (
  
  <Form.Group  md={grpmd} controlId={`validation${name}`} className="select-option form-field-group dropdown">
    { label
      ? <Form.Label className={`form-label ${labelClass}`}>{label}{required ? '*' : ''}</Form.Label>
      : ''

    }
<div className="d-md-flex form-page-radio">

    {selectvalues.length > 0 && selectvalues.map((item, index) => (

<Form.Check
    type={"radio"}
    label={item.label}
    id={item.label}
    checked={checked === item.value}
    required={required}
    value={item.value}
    name={name}
    onChange={handlechange}
    className="form-radio"
/>
    ))}
</div>
    
  </Form.Group>
  )
}

export default SelectField;