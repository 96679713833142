import React, { useEffect } from 'react';
import "./Elfsight.scss";

const ElfsightWidget = ({ widgetID, currentId }) => {
    useEffect(() => {
        // Check if the script already exists
        let script = document.querySelector('script[src="https://static.elfsight.com/platform/platform.js"]');
        let scriptCreated = false;
        
        if (!script) {
          script = document.createElement('script');
          script.src = 'https://static.elfsight.com/platform/platform.js';
          script.defer = true;
          document.body.appendChild(script);
          scriptCreated = true;
        }
    
        // Cleanup function to remove the script if it was added by this component
        return () => {
          if (scriptCreated) {
            document.body.removeChild(script);
          }
        };
    }, [widgetID]);

  return (
      <div className="elfsight-wrapper"> 
          {currentId &&
             <div className={`elfsight-app-${widgetID} ${currentId == widgetID? 'd-block' : 'd-none'}`} data-elfsight-app-lazy></div>
          }
         {!currentId &&
             <div className={`elfsight-app-${widgetID}`} data-elfsight-app-lazy></div>
         }
       
      </div>
  );
};

export default ElfsightWidget;