const spacingClassMap = {
    SectionPaddingBoth: 'spacing-default',
    SectionPaddingTop: 'spacing-top-only',
    SectionPaddingBottom: 'spacing-bottom-only'
  };
  
export const getSpacingClass = (sectionSpacingType) => spacingClassMap[sectionSpacingType] || '';

const propertySearchTypeSlugMap = {
  'sales': '/property-for-sale/',
  'lettings': '/property-to-rent/'
};

export const getPropertyUrl = (searchType, slug, propertyId) => {
  const baseUrl = propertySearchTypeSlugMap[searchType];
  if (!baseUrl) return '';
  
  return `${baseUrl}${slug}-${propertyId}`;
};